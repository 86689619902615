<template>
    <div class="list-wrap">
        <el-collapse v-model="activeNames" accordion class="list">
            <!-- <el-collapse-item title="[카테고리명] 질문 제목" name="1">
                 <div class="content">답변내용내용</div>
             </el-collapse-item>
             <el-collapse-item title="[카테고리명] 질문 제목" name="2">
                 <div class="content">답변내용내용</div>
             </el-collapse-item>
             <el-collapse-item title="[카테고리명카테고리명]  가나다라마바사아자차카타파하가나다라마바가나다라마바사아자차카타파하가나다라마바가나다라마바사아자차" name="3">
                 <div class="content">답변내용내용</div>
             </el-collapse-item>
             <el-collapse-item title="[카테고리명] 질문 제목" name="4">
                 <div class="content">
                     답변내용내용
                     <img src="@/assets/image/rebuild/img_1.jpg">

                 </div>
             </el-collapse-item>-->
            <el-collapse-item :title="`${returnCategoryName(board.fc_idx)} ${board.fa_subject}`"
                              :name="board.fa_idx"
                              v-for="board in boardData"
                              :key="`board${board.fa_idx}`">
                <div class="content" v-html="decodeHtml(board.fa_content)"></div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import 'element-ui/lib/theme-chalk/base.css';
    import util from "@/mixins/util";

    export default {
        name: "BoardListCollapseLayout",
        props: {
            boardData: {
                type: Array,
                default: () => {return []},
            },
            total: {
                type: Number,
                default: 0,
            },
            categorys: {
                type: Array,
                default: () => {return []},
            },
        },
        data() {
            return {
                activeNames: ['1'],
                categoryList: [],

            }
        },
        created() {
            this.getCategory();
        },
        computed: {

        },
        mounted() {

        },
        methods: {
            returnDate(date) {
                if(!date) {
                    return '';
                }
                return this.$moment(date).format('YYYY.MM.DD');
            },
            returnIndex(index) {
                return this.total - index;
            },
            goDetail(idx) {
                EventBus.$emit('boardGoDetail', idx)
            },
            getCategory() {
                try {
                    this.$api.$board.getFaqCategory({ip:0}).then(res => res.Data.Result).then(res => {
                        if(res.Info.type === 1) {
                            this.categoryList = res.List;
                        }
                    })
                }catch (e) {
                    console.log(e)
                }
            },
            returnCategoryName(fc_idx) {
                let categoryIndex = this.categoryList.findIndex(cate => cate.fc_idx === fc_idx);
                if(categoryIndex > -1) {
                    return `[${this.categoryList[categoryIndex].fc_category}]`;
                }
                return '';
            },
            decodeHtml(html) {
                return util.htmlDecode(html);
            },
        },
    }
</script>

<style scoped>

</style>